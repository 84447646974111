import {
  //@ts-ignore
  Modal,
  Form,
  //@ts-ignore
  FormGroup,
  TextInput,
  //@ts-ignore
  Stack,
  Button,
  //@ts-ignore
  InlineLoading,
  SkeletonText,
  Toggle,
  //@ts-ignore
  FormLabel,
} from "@carbon/react";
import { connect } from "react-redux";
import { FormState } from "../../Types";
import { userSlice, fetchUser, updateUser } from "../User.slice";
import { useEffect, useState } from "react";

const EditProfile = ({
  modalState,
  closeModal,
  username,
  email,
  form,
  updateUser,
  notifications_email,
  notifications_newsletter,
  notifications_push,
}: any) => {
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [toggleEmail, setToggleEmail] = useState(false);
  //setToggleEmail(notifications_email);
  const [toggleNewsletter, setToggleNewsletter] = useState(false);
  //setToggleNewsletter(notifications_newsletter);
  const [togglePush, setTogglePush] = useState(false);
  //setTogglePush(notifications_push);

  useEffect(() => {
    setToggleEmail(notifications_email);
    setToggleNewsletter(notifications_newsletter);
    setTogglePush(notifications_push);
  }, [notifications_email, notifications_newsletter, notifications_push]);

  const submitClick = () => {
    // TODO: use refs here?
    const username = document.getElementById(
      "edit-username"
    ) as HTMLInputElement;
    const email = document.getElementById("edit-email") as HTMLInputElement;
    // const toggle_email = document.getElementById("toggle-email") as any;
    // const toggle_newsletter = document.getElementById(
    //   "toggle-newsletter"
    // ) as any;
    // const toggle_push = document.getElementById("toggle-push") as any;

    updateUser(username.value, email.value, {
      email: toggleEmail,
      newsletter: toggleNewsletter,
      push: togglePush,
    });
  };
  return (
    <Modal
      modalHeading="Edit Profile"
      primaryButtonText="Save"
      secondaryButtonText="Close"
      open={modalState}
      passiveModal={true}
      onRequestClose={() => closeModal()}
    >
      {(() => {
        if (form === FormState.Pending) {
          return <SkeletonText />;
        } else {
          return (
            <Form>
              <Stack gap={7}>
                <TextInput
                  id="edit-username"
                  type="text"
                  labelText="USERNAME"
                  helperText="Your username on the platform."
                  defaultValue={username}
                  warn={false}
                  invalid={usernameInvalid}
                  disabled={false}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[A-Za-z][A-Za-z0-9_]{3,11}$/.test(value)) {
                      setUsernameInvalid(false);
                    } else {
                      setUsernameInvalid(true);
                    }
                  }}
                />
                <TextInput
                  id="edit-email"
                  type="email"
                  labelText="EMAIL"
                  helperText="Get notifications delivered to your email."
                  defaultValue={email}
                  warn={false}
                  invalid={emailInvalid}
                  disabled={false}
                  onChange={(e) => {
                    const value = e.target.value;
                    // value is a valid email
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                      setEmailInvalid(false);
                    } else {
                      setEmailInvalid(true);
                    }
                  }}
                />
                <FormLabel>Email Notifications</FormLabel>
                <Toggle
                  aria-label="toggle button"
                  defaultToggled={toggleEmail}
                  id="toggle-email"
                  labelA="Transactional Emails"
                  labelB="Transactional Emails"
                  onClick={() => {}}
                  onToggle={(toggleState) => {
                    setToggleEmail(toggleState);
                  }}
                />
                <Toggle
                  aria-label="toggle button"
                  defaultToggled={toggleNewsletter}
                  id="toggle-newsletter"
                  labelA="Newsletter"
                  labelB="Newsletter"
                  onClick={() => {}}
                  onToggle={(toggleState) => {
                    setToggleNewsletter(toggleState);
                  }}
                />
                <Toggle
                  aria-label="toggle button"
                  defaultToggled={togglePush}
                  id="toggle-push"
                  labelA="Push Notifications"
                  labelB="Push Notifications"
                  onClick={() => {}}
                  onToggle={(toggleState) => {
                    setTogglePush(toggleState);
                  }}
                />
                <FormGroup
                  legendText=""
                  style={{
                    display: "flex",
                    width: "300px",
                  }}
                >
                  <Button
                    kind="secondary"
                    onClick={closeModal}
                    disabled={false}
                  >
                    Cancel
                  </Button>
                  {(() => {
                    if (form === FormState.Ready) {
                      return (
                        <Button
                          onClick={() => {
                            submitClick();
                          }}
                        >
                          Submit
                        </Button>
                      );
                    }
                    if (form === FormState.Invalid) {
                      return (
                        <Button disabled={true} onClick={() => {}}>
                          Submit
                        </Button>
                      );
                    }
                    if (form === FormState.Submit) {
                      return (
                        <InlineLoading
                          style={{
                            marginLeft: "1rem",
                          }}
                          description="Saving..."
                          status="active"
                        />
                      );
                    }
                    if (form === FormState.Success) {
                      return (
                        <InlineLoading
                          style={{
                            marginLeft: "1rem",
                          }}
                          description="Saved!"
                          status="finished"
                        />
                      );
                    }
                    if (form === FormState.Error) {
                      return (
                        <InlineLoading
                          style={{
                            marginLeft: "1rem",
                          }}
                          description="Error!"
                          status="error"
                        />
                      );
                    }
                  })()}
                </FormGroup>
              </Stack>
            </Form>
          );
        }
      })()}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalState: state.user.editProfile.modal,
  username: state.user.userData.username,
  email: state.user.userData.email,
  notifications_email: state.user.userData.notificationSettings.email,
  notifications_newsletter: state.user.userData.notificationSettings.newsletter,
  notifications_push: state.user.userData.notificationSettings.push,
  form: state.user.editProfile.form,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(userSlice.actions.closeEditProfile()),
  fetchUser: () => dispatch(fetchUser()),
  updateUser: (username: string, email: string, notificationSettings: any) =>
    dispatch(updateUser({ username, email, notificationSettings })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
