import { createSlice } from "@reduxjs/toolkit";

// Theme
export enum Theme {
  DARK,
  LIGHT,
}

export interface ThemeSlice {
  theme: Theme;
}

// Initial state
const initialState: ThemeSlice = {
  theme: Theme.LIGHT,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state: ThemeSlice) => {
      state.theme = state.theme === Theme.DARK ? Theme.LIGHT : Theme.DARK;

      return state;
    },
  },
});
