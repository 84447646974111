import { Theme as CarbonTheme, GlobalTheme } from "@carbon/react";
import { connect } from "react-redux";
import { Theme } from "./Theme.slice";
import React from "react";

function Layout({ children, theme }: any) {
  let themeName: "g100" | "g10" = "g10";
  if (theme === Theme.DARK) {
    themeName = "g100";
  } else if (theme === Theme.LIGHT) {
    themeName = "g10";
  }
  return (
    <>
      <GlobalTheme theme={themeName}>
        <CarbonTheme theme={themeName}>{children}</CarbonTheme>
      </GlobalTheme>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
