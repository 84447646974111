import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { alchemyProvider } from "@wagmi/core/providers/alchemy";
import { infuraProvider } from "@wagmi/core/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  //walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import "@rainbow-me/rainbowkit/styles.css";
import React from "react";
import ConnectorSync from "./ConnectorSync";

export default function WalletWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet, sepolia],
    [
      alchemyProvider({ apiKey: "Jd5UBmFp5dF9ovCZy5fdFc-oWnG4JC85" }),
      infuraProvider({ apiKey: "dac1b2a461a54c139cc9739511494491" }),
      publicProvider(),
    ]
  );

  const connectors = connectorsForWallets([
    {
      groupName: "Recommended",
      wallets: [
        injectedWallet({ chains }),
        coinbaseWallet({
          appName: "nftx",
          chains,
        }),
        metaMaskWallet({ projectId: "nftx", chains }),
        // walletConnectWallet({
        //   chains,
        //   projectId: "c357aaf9d8a7e084d551e034518f4f91",
        // }),
      ],
    },
  ]);

  const config = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
  });

  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider
        chains={chains}
        modalSize="compact"
        theme={darkTheme({ borderRadius: "none" })}
      >
        <ConnectorSync />
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
