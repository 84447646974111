const NoScript = () => {
  return (
    <>
      <noscript>
        <div
          id="noscript"
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: "0",
            right: "0",
            top: "50%",
            textAlign: "center",
            width: "400px",
            padding: "25px",
            backgroundColor: "white",
            fontSize: "1.5rem",
          }}
        >
          This website requires JavaScript because it has interactive
          components.
        </div>
        <style type="text/css">
          {`
        #load {
            display: none;
        }
        `}
        </style>
      </noscript>
    </>
  );
};

export default NoScript;
