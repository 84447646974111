import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Grid,
  Column,
} from "@carbon/react";
import {
  InformationSquare as InformationSquareIcon,
  Thumbnail_2 as Thumbnail_2Icon,
  SettingsAdjust as SettingsAdjustIcon,
  Chat as ChatIcon,
  //@ts-ignore
} from "@carbon/icons-react";
import Meta from "./Meta";
import Settings from "./Settings";
import React from "react";
import Technical from "./Tabs/Technical";
import Social from "./Tabs/Social";

export default function ItemTabs(props: any) {
  return (
    <Tabs>
      <TabList aria-label="List of tabs" contained>
        <Tab renderIcon={InformationSquareIcon}>Meta</Tab>
        <Tab renderIcon={SettingsAdjustIcon}>Settings</Tab>
        <Tab renderIcon={Thumbnail_2Icon}>Technical</Tab>
        <Tab renderIcon={ChatIcon}>Social</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Meta />
        </TabPanel>
        <TabPanel>
          <Settings />
        </TabPanel>
        <TabPanel>
          <Technical />
        </TabPanel>
        <TabPanel>
          <Social />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
