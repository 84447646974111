import { createSlice } from "@reduxjs/toolkit";

export interface ChainWarningSlice {
  modalOpen: boolean;
}

// Initial state
const initialState: ChainWarningSlice = {
  modalOpen: false,
};

export const chainWarningSlice = createSlice({
  name: "chain_warning",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen;

      return state;
    },
    openModal: (state) => {
      state.modalOpen = true;

      return state;
    },
    closeModal: (state) => {
      state.modalOpen = false;

      return state;
    },
  },
});
