import { parseEther } from "ethers";
import { SidePanel } from "@carbon/ibm-products";
import React, { useEffect, useRef } from "react";
import { useAccount, useBalance, useContractWrite } from "wagmi";
import { wrappedEthABI } from "../generated";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  TextInput,
  Button,
} from "@carbon/react";

export default function EthSwap() {
  const [open, setOpen] = React.useState(false);
  const account = useAccount();
  const address = account?.address;

  const [eth, setEth] = React.useState(0);
  const [weth, setWeth] = React.useState(0);

  const ethBalance = useBalance({ address });
  const wethBalance = useBalance({
    address,
    token: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
  });
  //{ data, isLoading, isSuccess, write }
  const wrapWrite = useContractWrite({
    address: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
    abi: wrappedEthABI,
    functionName: "deposit",
    args: ["0.1"],
  });

  const unwrapWrite = useContractWrite({
    address: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
    abi: wrappedEthABI,
    functionName: "withdraw",
  });

  useEffect(() => {
    if (ethBalance.data) {
      setEth(ethBalance.data.formatted);
    }
    if (wethBalance.data) {
      setWeth(wethBalance.data.formatted);
    }
  }, [ethBalance, wethBalance]);

  const wrapValue = useRef(null);
  const unwrapValue = useRef(null);

  const wrap = () => {
    let value = wrapValue.current.value || "0";
    wrapWrite.write({
      args: [],
      from: address,
      value: parseEther(value),
    });
  };

  const unwrap = () => {
    let value = unwrapValue.current.value || "0";
    unwrapWrite.write({ value });
  };

  // open the side panel after 4 seconds
  setTimeout(() => {
    setOpen(true);
  }, 4000);

  return (
    <SidePanel
      includeOverlay
      className="eth-swap"
      open={open}
      onRequestClose={() => setOpen(false)}
      title="Swap ETH-WETH"
      subtitle="Wrap and Unwrap ETH/WETH"
      actions={[
        {
          label: "Swap",
          onClick: () => {},
          kind: "primary",
        },
        {
          label: "Cancel",
          onClick: () => setOpen(false),
          kind: "secondary",
        },
      ]}
    >
      <p>ETH Balance: {eth} ETH</p>
      <p>WETH Balance: {weth} ETH</p>
      <Tabs>
        <TabList aria-label="List of tabs">
          <Tab>Wrap</Tab>
          <Tab>Unwrap</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TextInput id="wrap-eth" type="text" labelText="" helperText="" />
          </TabPanel>
          <TabPanel>
            <TextInput id="unwrap-eth" type="text" labelText="" helperText="" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SidePanel>
  );
}
