import React from "react";
import { FlexGrid, Row, Column, Button } from "@carbon/react";
//@ts-ignore
//import { PageHeader, PageDescription } from "ibm-dotcom";
import { shellContained } from "./ShellContainer.module.scss";

export default function ShellContainer({
  pageTitle,
  pageDescription,
  pageHeader,
  children,
  ...props
}: any) {
  return (
    <div className={shellContained}>
      {pageHeader && pageHeader}
      <FlexGrid>
        <Row>
          <Column>
            <div style={{ margin: "0 1.5rem" }} {...props}>
              {children}
            </div>
          </Column>
        </Row>
      </FlexGrid>
    </div>
  );
}
// <div className={shellContained}>
//   {pageTitle && <PageHeader title={pageTitle} />}
//   {pageHeader && pageHeader}
//   <FlexGrid>
//     <Row>
//       <Column>
//         <div style={{ margin: "0 1.5rem" }} {...props}>
//           {pageDescription && (
//             <PageDescription>{pageDescription}</PageDescription>
//           )}
//           {children}
//         </div>
//       </Column>
//     </Row>
//   </FlexGrid>
// </div>;
// <FlexGrid>
//         <Row>
//           <Column>
//             <div style={{ margin: "0 1.5rem" }} {...props}>
//               {children}
//             </div>
//           </Column>
//         </Row>
//       </FlexGrid>
