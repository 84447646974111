import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { injectAlert } from "./index";

// Notifications State
export interface AlertSlice {}

// Initial State
const initialState: AlertSlice = {};

// Async Thunks
export const sendAlert = createAsyncThunk(
  "notifications/sendNotification",
  async ({ subtitle, title, kind }: any) => {
    injectAlert(subtitle, title, kind);
  },
);

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});
