import React from "react";
import { connect } from "react-redux";
import { SidePanel } from "@carbon/ibm-products";
import Tabs from "./Tabs";
import { itemSlice } from "../Item.slice";

function Panel({ itemPanel, closePanel }: any) {
  return (
    <div style={{ display: itemPanel ? "block" : "none" }}>
      <SidePanel
        className="inspect-artifact"
        open={true}
        onRequestClose={() => closePanel()}
        title="Inspect Artifact"
        subtitle=""
        actions={[
          {
            label: "Close",
            onClick: () => closePanel(),
            kind: "secondary",
          },
        ]}
      >
        <Tabs></Tabs>
      </SidePanel>
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  id: state.item.id,
  itemPanel: state.item.itemPanel,
});

const mapDispatchToProps = (dispatch: any) => ({
  closePanel: () => dispatch(itemSlice.actions.closeItemPanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
