import React from "react";
//@ts-ignore
import { HeaderGlobalAction, Modal } from "@carbon/react";
//@ts-ignore
import { WarningSquare as WarningSquareIcon } from "@carbon/icons-react";
import { connect } from "react-redux";
import { chainWarningSlice } from "./ChainWarning.slice";

function ChainWarningButton({ open }: any) {
  return (
    <HeaderGlobalAction aria-label="Non-Supported Network" onClick={open}>
      <WarningSquareIcon size={24} />
    </HeaderGlobalAction>
  );
}

function ModalChainWarning({ modalOpen, close }: any) {
  return (
    <Modal
      passiveModal
      modalHeading="Non-Supported Network"
      open={modalOpen}
      onRequestClose={close}
    >
      <p>Network not supported. Please switch to Ethereum Mainnet.</p>
    </Modal>
  );
}

const mapStateToProps = (state: any) => ({
  modalOpen: state.chainWarning.modalOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  open: () => dispatch(chainWarningSlice.actions.openModal()),
  close: () => dispatch(chainWarningSlice.actions.closeModal()),
});

const connectedChainWarningButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChainWarningButton);

const connectedModalChainWarning = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalChainWarning);

export { connectedChainWarningButton as ChainWarningButton };
export { connectedModalChainWarning as ModalChainWarning };
