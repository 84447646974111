import React from "react";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Section,
  Heading,
} from "@carbon/react";

function Meta({ id, meta }: any) {
  console.log(meta);
  // if meta is empty, return empty fragment
  if (Object.keys(meta).length === 0) {
    return <></>;
  }

  return (
    <>
      <Table
        aria-label="artifact-meta"
        size="sm"
        useStaticWidth={false}
        experimentalAutoAlign={false}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Key</TableHeader>
            <TableHeader>Value</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="name">
            <TableCell>Name</TableCell>
            <TableCell>{meta["name"]}</TableCell>
          </TableRow>
          <TableRow key="type">
            <TableCell>Type</TableCell>
            <TableCell>{meta["type"]}</TableCell>
          </TableRow>
          <TableRow key="image">
            <TableCell>Image</TableCell>
            <TableCell>{meta["image"]}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Section level={4} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Heading>Attributes</Heading>
      </Section>
      <Table
        aria-label="artifact-meta-attributes"
        size="sm"
        useStaticWidth={false}
        experimentalAutoAlign={false}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Key</TableHeader>
            <TableHeader>Value</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {meta["attributes"].map((attribute: any, index: number) => (
            <TableRow key={index}>
              <TableCell>{attribute["trait_type"]}</TableCell>
              <TableCell>{attribute["value"]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  id: state.item.id,
  meta: state.item.meta,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Meta);
