import { DotMark as DotMarkIcon } from "@carbon/icons-react";
import React, { useEffect, useState } from "react";

import {
  networkStatus,
  networkStatusLoading,
  networkStatusError,
} from "./NetworkStatus.module.scss";

import { useBlockNumber } from "wagmi";

export default function NetworkStatus() {
  const [blockNumber, setBlockNumber] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");

  const { data, isLoading, isError } = useBlockNumber({
    onBlock(blockNumber) {},
    onError(error) {
      setError(true);
    },
    watch: true,
    cacheTime: 15_000,
  });

  useEffect(() => {
    // convert block number to number
    const blockNumber = Number(data);
    setBlockNumber(blockNumber);

    if (isError) {
      setError(true);
    } else {
      setError(false);
    }

    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    // set url to etherscan block explorer
    // TODO: this should be based on the network
    setUrl(`https://etherscan.io/block/${blockNumber}`);
  }, [data, isLoading, isError]);

  if (loading) {
    <div className={networkStatusLoading}>
      <a href="#">
        <DotMarkIcon />| Connecting...
      </a>
    </div>;
  }
  if (error) {
    <div className={networkStatusError}>
      <a href="#">
        <DotMarkIcon />| Network Error
      </a>
    </div>;
  }
  return (
    <div className={networkStatus}>
      <a href={url} target="_blank" rel="noreferrer">
        <DotMarkIcon />| {blockNumber}
      </a>
    </div>
  );
}
