import {
  createListenerMiddleware,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

export enum LoadState {
  NOT_LOADED,
  LOADING,
  LOADED,
  FAILED,
}

export interface FrameSettingsState {
  geolocation: boolean | null;
  gyroscope: boolean | null;
  accelerometer: boolean | null;
  microphone: boolean | null;
  camera: boolean | null;
  fullscreen: boolean | null;
}

const defaultFrameSettingsState: FrameSettingsState = {
  geolocation: false,
  gyroscope: false,
  accelerometer: false,
  microphone: false,
  camera: false,
  fullscreen: false,
};

// Define a SettingsState type which is a hashmap of string to FrameSettingsState
export interface SettingsState {
  [key: string]: FrameSettingsState;
}

export interface ItemSlice {
  id: string | boolean;
  meta: any;
  wasmState: LoadState;
  metaState: LoadState;
  thumbnailState: LoadState;
  settings: SettingsState;
  itemPanel: boolean;
}

const initialState = {
  id: false,
  meta: {},
  settings: {},
  wasmState: LoadState.NOT_LOADED,
  metaState: LoadState.NOT_LOADED,
  thumbnailState: LoadState.NOT_LOADED,
  itemPanel: false,
};

export const itemFullScreen = createAsyncThunk("item/fullScreen", async () => {
  const elem = document.querySelector("iframe");
  if (elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }
});

export const getSetting = createAsyncThunk(
  "item/getSetting",
  async ({ id, setting }: any, ThunkAPI: any) => {
    // check if settings[id] exists
    if (!ThunkAPI.getState().item.settings.hasOwnProperty(id)) {
      return defaultFrameSettingsState[setting];
    }

    // check if settings[id][setting] exists
    if (!ThunkAPI.getState().item.settings[id].hasOwnProperty(setting)) {
      return defaultFrameSettingsState[setting];
    }

    return ThunkAPI.getState().item.settings[id][setting];
  }
);

export const setItem = createAsyncThunk(
  "item/setItem",
  async ({ id }: any, ThunkAPI: any) => {
    let url = `https://portal.nftxledger.com/${id}/metadata`;
    console.log(url);

    // fetch url
    const response = await fetch(url);

    const meta = await response.json();

    // set metadata
    ThunkAPI.dispatch(itemSlice.actions.setMeta(meta));

    // set id
    ThunkAPI.dispatch(itemSlice.actions.setItem(id));
  }
);

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setItem: (state: any, action: any) => {
      state.id = action.payload;

      return state;
    },
    setMeta: (state: any, action: any) => {
      state.meta = action.payload;

      return state;
    },
    setSetting: (state: any, action: any) => {
      const { payload } = action;
      const { id, setting, value } = payload;

      if (!state.settings.hasOwnProperty(id)) {
        state.settings[id] = {}; // create new settings object for this id
      }

      state.settings[id][setting] = value;

      return state;
    },
    setWasmState: (state: any, action: any) => {
      state.wasmState = action.payload;

      return state;
    },
    openItemPanel: (state: any) => {
      state.itemPanel = true;

      return state;
    },
    closeItemPanel: (state: any) => {
      state.itemPanel = false;

      return state;
    },
    toggleItemPanel: (state: any) => {
      state.itemPanel = !state.itemPanel;

      return state;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(setItem.pending, (state: any, action: any) => {
      state.metaState = LoadState.LOADING;

      return state;
    });
    builder.addCase(setItem.fulfilled, (state: any, action: any) => {
      state.metaState = LoadState.LOADED;

      return state;
    });
    builder.addCase(setItem.rejected, (state: any, action: any) => {
      state.metaState = LoadState.FAILED;

      return state;
    });
  },
});

// Create Listener Middleware
const listenerMiddleware = createListenerMiddleware();
export const itemListenerMiddleware = listenerMiddleware;

// Watch for signIn.fulfilled and dispatch fetchUser
// listenerMiddleware.startListening({
//   actionCreator: itemSlice.actions.setItem,
//   effect: async (action, listenerApi) => {
//     const { dispatch } = listenerApi;
//     const { payload } = action;
//     let id = payload;
//     let url = `https://portal.nftxledger.com/${id}/metadata`;
//     // fetch url

//     const response = await fetch(url);
//     const meta = await response.json();

//     dispatch(itemSlice.actions.setMeta(meta));
//   },
// });
