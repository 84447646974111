import {
  //@ts-ignore
  HeaderGlobalAction,
  //@ts-ignore
  HeaderPanel,
  //@ts-ignore
  SideNavItems,
  //@ts-ignore
  SideNavMenu,
} from "@carbon/react";
import {
  UserAvatar as UserAvatarIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  At as AtIcon,
  Logout as LogoutIcon,
  Network_1 as NetworkIcon,
  Wallet as WalletIcon,
  Copy as CopyIcon,
  Upload as UploadIcon,
  UserData as UserDataIcon,
  //@ts-ignore
} from "@carbon/icons-react";
import { UserSlice, userSlice, toggleUserTab } from "./User.slice";
import { connect } from "react-redux";
import { AuthState, disconnectWallet } from "../Wallet/Wallet.slice";
import { useAccount, useBalance, useDisconnect, useNetwork } from "wagmi";
import React from "react";
import { sendAlert } from "../Alert/Alert.slice";
import { SwitcherItem } from "../Utils";

const UserTab = ({ profileExpanded, toggleProfile, authState }: any) => {
  let isDisabled = authState === AuthState.SIGNED_IN ? false : true;

  return (
    <HeaderGlobalAction
      aria-label="User Profile"
      aria-expanded={profileExpanded}
      isActive={profileExpanded}
      disabled={isDisabled}
      onClick={() => toggleProfile()}
    >
      <UserAvatarIcon size={24} />
    </HeaderGlobalAction>
  );
};

const UserPanel = ({
  profileExpanded,
  toggleProfile,
  openEditProfile,
  username,
  email,
  sendAlert,
  children,
}: any) => {
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { data } = useBalance({ address });

  const wagmiDisconnect = useDisconnect(); // Get the disconnect function from Wagmi

  let s_address: string = "0x00";

  if (address != undefined) {
    s_address = address;
  }

  let chainName: string;
  let chainNetwork: string;
  if (chain === undefined) {
    chainName = "unknown";
    chainNetwork = "unknown";
  } else {
    chainName = chain.name;
    chainNetwork = chain.network;
  }

  let balance = "unknown";
  if (data != undefined) {
    balance = data.formatted;
  }

  function disconnect(e: any) {
    e.preventDefault();

    wagmiDisconnect.disconnect();
    toggleProfile();
  }

  function copyAddress(e: any) {
    // copy address to clipboard
    e.preventDefault();

    sendAlert({
      subtitle: "Address copied to clipboard",
      title: "",
      kind: "info",
    });

    navigator.clipboard.writeText(s_address);
  }

  return (
    <HeaderPanel
      expanded={profileExpanded}
      addFocusListeners={false}
      onHeaderPanelFocus={() => {}}
    >
      <SideNavItems>
        <SideNavMenu title="Wallet" defaultExpanded={true}>
          <SwitcherItem aria-label="User address" href="" onClick={copyAddress}>
            <CopyIcon size={22} />
            {formatAddress(s_address)}
          </SwitcherItem>
          <SwitcherItem
            aria-label=""
            outbound={true}
            target="_blank"
            href={etherscanLink(s_address, chainNetwork)}
          >
            <WalletIcon size={22} />
            {formatBalance(balance)}
          </SwitcherItem>
          <SwitcherItem aria-label="" href="#">
            <NetworkIcon size={22} />
            {chainName}
          </SwitcherItem>
          <SwitcherItem aria-label="" href="" onClick={disconnect}>
            <LogoutIcon size={22} />
            Log out
          </SwitcherItem>
          <div
            style={{
              paddingBottom: "1.5rem",
            }}
          ></div>
        </SideNavMenu>
        <SideNavMenu aria-label="" title="Profile" defaultExpanded={true}>
          <SwitcherItem>
            <AtIcon size={22} />
            {username}
          </SwitcherItem>
          <SwitcherItem aria-label="">
            <EmailIcon size={22} />
            {email}
          </SwitcherItem>
          <SwitcherItem
            aria-label=""
            href=""
            onClick={(e: any) => {
              e.preventDefault();
              openEditProfile();
            }}
          >
            <EditIcon size={22} />
            Edit Profile
          </SwitcherItem>
          <div
            style={{
              paddingBottom: "1.5rem",
            }}
          ></div>
        </SideNavMenu>
        {children}
      </SideNavItems>
    </HeaderPanel>
  );
};

const mapStateToProps = (state: any) => ({
  profileExpanded: state.user.userTab,
  walletState: state.auth.walletState,
  authState: state.auth.authState,
  username: state.user.userData.username,
  email: state.user.userData.email,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleProfile: () => dispatch(toggleUserTab()),
  openEditProfile: () => dispatch(userSlice.actions.openEditProfile()),
  disconnectWallet: () => dispatch(disconnectWallet()),
  sendAlert: (notification: any) => dispatch(sendAlert(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTab);

const connectedUserPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPanel);

export { connectedUserPanel as UserPanel };

const formatAddress = (address: string) => {
  const first = address.substring(0, 6);
  const last = address.substring(address.length - 4, address.length);
  return `${first}...${last}`;
};

const formatBalance = (balance: string) => {
  balance = balance.substring(0, balance.indexOf(".") + 5);

  return balance;
};

const etherscanLink = (address: string, network: string) => {
  if (network === "homestead") {
    return `https://etherscan.io/address/${address}`;
  } else if (network === "sepolia") {
    return `https://sepolia.etherscan.io/address/${address}`;
  } else if (network === "matic") {
    return `https://polygonscan.com/address/${address}`;
  } else if (network === "arbitrum") {
    return `https://arbiscan.io/address/${address}`;
  } else {
    return "";
  }
};
