import React from "react";
import { connect } from "react-redux";
import { InlineNotification } from "@carbon/react";

function Social() {
  return (
    <>
      <InlineNotification
        aria-label="Close notification"
        kind="info"
        onClose={function noRefCheck() {}}
        onCloseButtonClick={function noRefCheck() {}}
        statusIconDescription="notification"
        hideCloseButton={true}
        subtitle="Social is still under development."
        title="Social Tab"
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Social);
