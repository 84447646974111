import React from "react";
import { connect } from "react-redux";
import { InlineNotification } from "@carbon/react";

function Technical() {
  return (
    <>
      <InlineNotification
        aria-label="Close notification"
        kind="info"
        onClose={function noRefCheck() {}}
        onCloseButtonClick={function noRefCheck() {}}
        statusIconDescription="notification"
        hideCloseButton={true}
        subtitle="Technical details are still under development."
        title="Technical Tab"
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Technical);
