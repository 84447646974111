import React, { useEffect } from "react";
import { connect } from "react-redux";
//@ts-ignore
import { Modal, ModalBody } from "@carbon/react";
import {
  authSlice,
  prepareSignIn,
  WalletState,
  AuthState,
} from "./Wallet.slice";

function ModalSignMessage({
  modalOpen,
  close,
  prepareSignIn,
  walletState,
  authState,
}: any) {
  useEffect(() => {}, [walletState, authState]);
  if (authState === AuthState.SIGNED_IN) {
    close();
  }
  return (
    <Modal
      modalHeading="Verify Account Ownership"
      open={modalOpen}
      onRequestClose={close}
      primaryButtonText="Authenticate"
      secondaryButtonText="Cancel"
      onRequestSubmit={prepareSignIn}
    >
      <ModalBody>
        <p className="bx--modal-content__text bx--modal-content__regular-content">
          Confirm you are the owner of this wallet by signin an authentication
          message. This operation does not consume any gas and does not trigger
          any blockchain transactions.
        </p>
        <p
          className="bx--modal-content__text bx--modal-content__regular-content"
          style={{ marginTop: "2rem" }}
        >
          NFTx adheres to the{" "}
          <a href="https://eips.ethereum.org/EIPS/eip-4361">
            ERC-4361 Standard
          </a>
          .
        </p>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state: any) => ({
  authState: state.auth.authState,
  walletState: state.auth.walletState,
  modalOpen: state.auth.modalOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  close: () => dispatch(authSlice.actions.closeModal()),
  prepareSignIn: () => dispatch(prepareSignIn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSignMessage);
