import { useMutation } from "urql";

export const authenticateMessage = `
mutation ($message: SiweMessage!, $signature: String!) {
    authenticate(
        message: $message,
        signature: $signature
    ) {
        raw
    }
}
`;

// const Todo = ({ id, title }) => {
//   const [updateTodoResult, updateTodo] = useMutation(UpdateTodo);
// };

// export const authenticateMessage = async (message: any, signature: string) => {
//     const [result, executeMutation] = useMutation(authenticateMessageMutation);

//     await executeMutation({
//         message,
//         signature
//     });
//
//    return result;
// }
