import { useConnectModal } from "@rainbow-me/rainbowkit";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import {
  connectWallet,
  signOut,
  disconnectWallet,
  setChain,
  setAddress,
} from "./Wallet.slice";

function ConnectorSync({
  connectWallet,
  signOut,
  disconnectWallet,
  setChain,
  setAddress,
}: any) {
  // Synchronize Web3.0 Hooks with the Redux Store
  const account = useAccount(); // Get the account from Wagmi
  const network = useNetwork(); // Get the chain from Wagmi

  useEffect(() => {
    // Set the Wallet State (Connected/Disconnected)
    if (account.status === "connected") {
      connectWallet();
    }
    if (account.status === "disconnected") {
      signOut();
      disconnectWallet();
    }

    // Set the Network Chain
    if (network.chain && network.chain.id) {
      setChain({ chainId: network.chain.id });
      setAddress({ address: account.address });
    }
  }, [network, account]);

  return <></>;
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  connectWallet: () => dispatch(connectWallet()),
  signOut: () => dispatch(signOut()),
  disconnectWallet: () => dispatch(disconnectWallet()),
  setChain: ({ chainId }: { chainId: number }) =>
    dispatch(setChain({ chainId })),
  setAddress: ({ address }: { address: string }) =>
    dispatch(setAddress({ address })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorSync);
