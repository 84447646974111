import { gql, useMutation } from "urql";

export const generateNonce = gql`
  mutation {
    nonce
  }
`;

// export const generateNonce = async () => {
//     const [result, executeMutation] = useMutation(generateNonceMutation);
//
//     await executeMutation({});
//
//     return result;
// };
