import { Client, cacheExchange, fetchExchange } from "urql";

export const getClient = () => {
  if (window && window.graphqlClient) {
    return window.graphqlClient;
  }

  const endpoint = "https://wasm-gateway.aderbal.workers.dev";

  let client = new Client({
    url: endpoint,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => {
      const token = getToken();
      return {
        headers: { authorization: token ? `Bearer ${token}` : "" },
      };
    },
  });

  window.graphqlClient = client;

  return client;
};

const getToken = () => {
  const storage = window.localStorage;
  const token = storage.getItem("auth_token");

  if (!token) {
    return false;
  }

  return token;
};
