import {
  Button,
  Column,
  FlexGrid,
  Grid,
  Row,
  InlineLoading,
  SkeletonPlaceholder,
} from "@carbon/react";
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { itemSlice, LoadState } from "../Item.slice";

function Frame({ id, meta, wasmState, setWasmState }: any) {
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (id) {
      setUrl(`https://sandbox.nftxledger.com/${id}`);
      setWasmState(LoadState.LOADING);
    }
  }, [id]);

  window.addEventListener("message", receiveMessage, false);
  function receiveMessage(event: any) {
    if (event.origin !== "https://sandbox.nftxledger.com") return;
    if (event.data !== "1") return;

    setWasmState(LoadState.LOADED);
  }

  return (
    <div style={{ border: "" }}>
      <FrameLoading wasmState={wasmState} />
      <iframe
        src={url}
        width="100%"
        height="750px"
        data-isloaded="0"
        style={{
          visibility: wasmState === LoadState.LOADED ? "visible" : "hidden",
        }}
      ></iframe>
    </div>
  );
}

const FrameLoading = ({ wasmState }: any) => {
  if (wasmState === LoadState.LOADING) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <InlineLoading
            status="active"
            iconDescription="Loading"
            description="Loading WASM Artifact..."
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: any) => ({
  id: state.item.id,
  meta: state.item.meta,
  wasmState: state.item.wasmState,
});

const mapDispatchToProps = (dispatch: any) => ({
  setWasmState: (wasmState: any) =>
    dispatch(itemSlice.actions.setWasmState(wasmState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Frame);
