import {
  useContractEvent,
  UseContractEventConfig,
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  Address,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
} from "wagmi";
import {
  ReadContractResult,
  WriteContractMode,
  PrepareWriteContractResult,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NFTxLedger
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export const nfTxLedgerABI = [
  {
    stateMutability: "payable",
    type: "constructor",
    inputs: [
      { name: "_logic", internalType: "address", type: "address" },
      { name: "_data", internalType: "bytes", type: "bytes" },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "newAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "beacon",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BeaconUpgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "implementation",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Upgraded",
  },
  { stateMutability: "payable", type: "fallback" },
  { stateMutability: "payable", type: "receive" },
] as const;

/**
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export const nfTxLedgerAddress = {
  11155111: "0xCB19467E7B8c326CD7A49193BD2418B2301B8D27",
} as const;

/**
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export const nfTxLedgerConfig = {
  address: nfTxLedgerAddress,
  abi: nfTxLedgerABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WrappedEth
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export const wrappedEthABI = [
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "guy", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "src", type: "address" },
      { name: "dst", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", type: "bool" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "wad", type: "uint256" }],
    name: "withdraw",
    outputs: [],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
  },
  {
    constant: false,
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "dst", type: "address" },
      { name: "wad", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
  },
  {
    constant: false,
    payable: true,
    stateMutability: "payable",
    type: "function",
    inputs: [],
    name: "deposit",
    outputs: [],
  },
  {
    constant: true,
    payable: false,
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "", type: "address" },
      { name: "", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", type: "uint256" }],
  },
  { payable: true, stateMutability: "payable", type: "fallback" },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "src", type: "address", indexed: true },
      { name: "guy", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Approval",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "src", type: "address", indexed: true },
      { name: "dst", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Transfer",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "dst", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Deposit",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "src", type: "address", indexed: true },
      { name: "wad", type: "uint256", indexed: false },
    ],
    name: "Withdrawal",
  },
] as const;

/**
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export const wrappedEthAddress = {
  11155111: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
} as const;

/**
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export const wrappedEthConfig = {
  address: wrappedEthAddress,
  abi: wrappedEthABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link nfTxLedgerABI}__.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export function useNfTxLedgerEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof nfTxLedgerABI, TEventName>,
    "abi" | "address"
  > & { chainId?: keyof typeof nfTxLedgerAddress } = {} as any
) {
  return useContractEvent({
    abi: nfTxLedgerABI,
    address: nfTxLedgerAddress[11155111],
    ...config,
  } as UseContractEventConfig<typeof nfTxLedgerABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link nfTxLedgerABI}__ and `eventName` set to `"AdminChanged"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export function useNfTxLedgerAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof nfTxLedgerABI, "AdminChanged">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof nfTxLedgerAddress } = {} as any
) {
  return useContractEvent({
    abi: nfTxLedgerABI,
    address: nfTxLedgerAddress[11155111],
    eventName: "AdminChanged",
    ...config,
  } as UseContractEventConfig<typeof nfTxLedgerABI, "AdminChanged">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link nfTxLedgerABI}__ and `eventName` set to `"BeaconUpgraded"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export function useNfTxLedgerBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof nfTxLedgerABI, "BeaconUpgraded">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof nfTxLedgerAddress } = {} as any
) {
  return useContractEvent({
    abi: nfTxLedgerABI,
    address: nfTxLedgerAddress[11155111],
    eventName: "BeaconUpgraded",
    ...config,
  } as UseContractEventConfig<typeof nfTxLedgerABI, "BeaconUpgraded">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link nfTxLedgerABI}__ and `eventName` set to `"Upgraded"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCB19467E7B8c326CD7A49193BD2418B2301B8D27)
 */
export function useNfTxLedgerUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof nfTxLedgerABI, "Upgraded">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof nfTxLedgerAddress } = {} as any
) {
  return useContractEvent({
    abi: nfTxLedgerABI,
    address: nfTxLedgerAddress[11155111],
    eventName: "Upgraded",
    ...config,
  } as UseContractEventConfig<typeof nfTxLedgerABI, "Upgraded">);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"name"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthName<
  TFunctionName extends "name",
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "name",
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"totalSupply"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthTotalSupply<
  TFunctionName extends "totalSupply",
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "totalSupply",
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"decimals"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthDecimals<
  TFunctionName extends "decimals",
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "decimals",
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"balanceOf"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthBalanceOf<
  TFunctionName extends "balanceOf",
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "balanceOf",
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"symbol"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthSymbol<
  TFunctionName extends "symbol",
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "symbol",
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"allowance"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthAllowance<
  TFunctionName extends "allowance",
  TSelectData = ReadContractResult<typeof wrappedEthABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractRead({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "allowance",
    ...config,
  } as UseContractReadConfig<typeof wrappedEthABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wrappedEthABI}__.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wrappedEthAddress
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wrappedEthABI,
          string
        >["request"]["abi"],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof wrappedEthABI, TFunctionName, TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
      } = {} as any
) {
  return useContractWrite<typeof wrappedEthABI, TFunctionName, TMode>({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wrappedEthAddress
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wrappedEthABI,
          "approve"
        >["request"]["abi"],
        "approve",
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: "approve" }
    : UseContractWriteConfig<typeof wrappedEthABI, "approve", TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: "approve";
      } = {} as any
) {
  return useContractWrite<typeof wrappedEthABI, "approve", TMode>({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "approve",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wrappedEthAddress
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wrappedEthABI,
          "transferFrom"
        >["request"]["abi"],
        "transferFrom",
        TMode
      > & {
        address?: Address;
        chainId?: TChainId;
        functionName?: "transferFrom";
      }
    : UseContractWriteConfig<typeof wrappedEthABI, "transferFrom", TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: "transferFrom";
      } = {} as any
) {
  return useContractWrite<typeof wrappedEthABI, "transferFrom", TMode>({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "transferFrom",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wrappedEthAddress
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wrappedEthABI,
          "withdraw"
        >["request"]["abi"],
        "withdraw",
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: "withdraw" }
    : UseContractWriteConfig<typeof wrappedEthABI, "withdraw", TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: "withdraw";
      } = {} as any
) {
  return useContractWrite<typeof wrappedEthABI, "withdraw", TMode>({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "withdraw",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"transfer"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wrappedEthAddress
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wrappedEthABI,
          "transfer"
        >["request"]["abi"],
        "transfer",
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: "transfer" }
    : UseContractWriteConfig<typeof wrappedEthABI, "transfer", TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: "transfer";
      } = {} as any
) {
  return useContractWrite<typeof wrappedEthABI, "transfer", TMode>({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "transfer",
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"deposit"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthDeposit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wrappedEthAddress
>(
  config: TMode extends "prepared"
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wrappedEthABI,
          "deposit"
        >["request"]["abi"],
        "deposit",
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: "deposit" }
    : UseContractWriteConfig<typeof wrappedEthABI, "deposit", TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: "deposit";
      } = {} as any
) {
  return useContractWrite<typeof wrappedEthABI, "deposit", TMode>({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "deposit",
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wrappedEthABI}__.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function usePrepareWrappedEthWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wrappedEthABI, TFunctionName>,
    "abi" | "address"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    ...config,
  } as UsePrepareContractWriteConfig<typeof wrappedEthABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function usePrepareWrappedEthApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wrappedEthABI, "approve">,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "approve",
    ...config,
  } as UsePrepareContractWriteConfig<typeof wrappedEthABI, "approve">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function usePrepareWrappedEthTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wrappedEthABI, "transferFrom">,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "transferFrom",
    ...config,
  } as UsePrepareContractWriteConfig<typeof wrappedEthABI, "transferFrom">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function usePrepareWrappedEthWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wrappedEthABI, "withdraw">,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "withdraw",
    ...config,
  } as UsePrepareContractWriteConfig<typeof wrappedEthABI, "withdraw">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"transfer"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function usePrepareWrappedEthTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wrappedEthABI, "transfer">,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "transfer",
    ...config,
  } as UsePrepareContractWriteConfig<typeof wrappedEthABI, "transfer">);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wrappedEthABI}__ and `functionName` set to `"deposit"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function usePrepareWrappedEthDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wrappedEthABI, "deposit">,
    "abi" | "address" | "functionName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    functionName: "deposit",
    ...config,
  } as UsePrepareContractWriteConfig<typeof wrappedEthABI, "deposit">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wrappedEthABI}__.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof wrappedEthABI, TEventName>,
    "abi" | "address"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractEvent({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    ...config,
  } as UseContractEventConfig<typeof wrappedEthABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wrappedEthABI}__ and `eventName` set to `"Approval"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof wrappedEthABI, "Approval">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractEvent({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    eventName: "Approval",
    ...config,
  } as UseContractEventConfig<typeof wrappedEthABI, "Approval">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wrappedEthABI}__ and `eventName` set to `"Transfer"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof wrappedEthABI, "Transfer">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractEvent({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    eventName: "Transfer",
    ...config,
  } as UseContractEventConfig<typeof wrappedEthABI, "Transfer">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wrappedEthABI}__ and `eventName` set to `"Deposit"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthDepositEvent(
  config: Omit<
    UseContractEventConfig<typeof wrappedEthABI, "Deposit">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractEvent({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    eventName: "Deposit",
    ...config,
  } as UseContractEventConfig<typeof wrappedEthABI, "Deposit">);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wrappedEthABI}__ and `eventName` set to `"Withdrawal"`.
 *
 * [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14)
 */
export function useWrappedEthWithdrawalEvent(
  config: Omit<
    UseContractEventConfig<typeof wrappedEthABI, "Withdrawal">,
    "abi" | "address" | "eventName"
  > & { chainId?: keyof typeof wrappedEthAddress } = {} as any
) {
  return useContractEvent({
    abi: wrappedEthABI,
    address: wrappedEthAddress[11155111],
    eventName: "Withdrawal",
    ...config,
  } as UseContractEventConfig<typeof wrappedEthABI, "Withdrawal">);
}
