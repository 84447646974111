import { Column, Grid, Toggle } from "@carbon/react";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { getSetting, itemSlice } from "../Item.slice";

async function Settings({ id, getSetting, setSetting }: any) {
  return (
    <>
      <Grid>
        <Column lg={8}>
          <Toggle
            labelA="Geolocation"
            labelB="Geolocation"
            defaultToggled={await getSetting(id, "geolocation").payload}
            id="toggle-geolocation"
            size="sm"
            onClick={async () => {
              let value = !(await getSetting(id, "geolocation")).payload;
              setSetting(id, "geolocation", value);
            }}
          />
        </Column>
        <Column lg={8}>
          <Toggle
            labelA="Gyroscope"
            labelB="Gyroscope"
            defaultToggled={(await getSetting(id, "gyroscope")).payload}
            id="toggle-gyroscope"
            size="sm"
            onClick={async () => {
              let value = !(await getSetting(id, "gyroscope")).payload;
              setSetting(id, "gyroscope", value);
            }}
          />
        </Column>
      </Grid>
      <Grid>
        <Column lg={8}>
          <Toggle
            labelA="Accelerometer"
            labelB="Accelerometer"
            defaultToggled={(await getSetting(id, "accelerometer")).payload}
            id="toggle-accelerometer"
            size="sm"
            onClick={async () => {
              let value = !(await getSetting(id, "accelerometer")).payload;
              setSetting(id, "accelerometer", value);
            }}
          />
        </Column>
        <Column lg={8}>
          <Toggle
            labelA="Microphone"
            labelB="Microphone"
            defaultToggled={(await getSetting(id, "microphone")).payload}
            id="toggle-microphone"
            size="sm"
            onClick={async () => {
              let value = !(await getSetting(id, "microphone")).payload;
              setSetting(id, "microphone", value);
            }}
          />
        </Column>
      </Grid>
      <Grid>
        <Column lg={8}>
          <Toggle
            labelA="Camera"
            labelB="Camera"
            defaultToggled={(await getSetting(id, "camera")).payload}
            id="toggle-camera"
            size="sm"
            onClick={async () => {
              let value = !(await getSetting(id, "camera")).payload;
              setSetting(id, "camera", value);
            }}
          />
        </Column>
        <Column lg={8}>
          <Toggle
            labelA="Fullscreen"
            labelB="Fullscreen"
            defaultToggled={(await getSetting(id, "fullscreen")).payload}
            id="toggle-fullscreen"
            size="sm"
            onClick={async () => {
              let value = !(await getSetting(id, "fullscreen")).payload;
              setSetting(id, "fullscreen", value);
            }}
          />
        </Column>
      </Grid>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  id: state.item.id,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSetting: (id: any, setting: any) => dispatch(getSetting({ id, setting })),
  setSetting: (id: any, setting: any, value: any) =>
    dispatch(itemSlice.actions.setSetting({ id, setting, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
