import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useAccount, useSignMessage, useDisconnect, useNetwork } from "wagmi";
import { SiweMessage } from "siwe";
import {
  connectWallet,
  disconnectWallet,
  prepareSignIn,
  signIn,
  signOut,
  WalletState,
  AuthState,
} from "./Wallet.slice";

import { useConnectModal } from "@rainbow-me/rainbowkit";

import {
  Wallet as WalletIcon,
  Logout as LogoutIcon,
  IbmCloudHyperProtectCryptoServices as SignIcon,
  Misuse as MisuseIcon,
  IntentRequestUpgrade as WaitIcon,
  ResetAlt as ResetAltIcon,
  //@ts-ignore
} from "@carbon/icons-react";
//@ts-ignore
import { HeaderGlobalAction } from "@carbon/react";
import { useClient } from "urql";
import { ChainWarningButton } from "../ChainWarning";

function WalletConnect({
  connectWallet,
  signIn,
  disconnectWallet,
  signOut,
  prepareSignIn,
  walletState,
  authState,
  preparedMessage,
}: any) {
  let client = useClient(); // Get the Urql client from the context
  const { openConnectModal, connectModalOpen } = useConnectModal(); // Get the connect modal from RainbowKit
  const { status, address } = useAccount(); // Get the account from Wagmi
  const { disconnect } = useDisconnect(); // Get the disconnect function from Wagmi
  const { chain } = useNetwork(); // Get the chain from Wagmi

  // const { signMessage } = useSignMessage({
  //   onSuccess: (signature: string) => {
  //     signIn(signature, client);
  //   },
  // });

  // Sync the wallet client with the Redux store
  useEffect(() => {
    //   switch (status) {
    //     case "connected":
    //       connectWallet(chain);
    //       break;
    //     case "disconnected":
    //       signOut(); // Fully sign out
    //       disconnectWallet();
    //       break;
    //   }
    //   switch (authState) {
    //     case AuthState.MESSAGE_READY:
    //       const message = new SiweMessage(preparedMessage).prepareMessage();
    //       signMessage({
    //         message,
    //       });
    //       break;
    //   }
  }, [walletState, authState, openConnectModal]);

  // Display Chain Warning if chain is not accepted
  let chainId: number | boolean = 1;

  let acceptedChains = [1, 11155111];

  if (chain != undefined) {
    // check if chain is accepted
    if (acceptedChains.includes(chain.id)) {
      chainId = chain.id;
    } else {
      chainId = false;
    }
  }
  // if (chainId == false) {
  //   return <ChainWarningButton />;
  // }

  // Display Connection Toolbar
  switch (walletState) {
    case WalletState.CHAIN_WARNING:
      return <ChainWarningButton />;
    case WalletState.DISCONNECTED:
      if (openConnectModal == undefined) {
        return (
          <HeaderGlobalAction aria-label="Wallet Error">
            <MisuseIcon size={22} />
          </HeaderGlobalAction>
        );
      } else {
        return (
          <HeaderGlobalAction
            aria-label="Connect Wallet"
            onClick={() => openConnectModal()}
          >
            <WalletIcon size={22} />
          </HeaderGlobalAction>
        );
      }
    case WalletState.CONNECTED:
      switch (authState) {
        case AuthState.SIGNED_OUT:
          return (
            <>
              <HeaderGlobalAction
                aria-label="Authenticate Wallet"
                onClick={() => prepareSignIn(client)}
              >
                <SignIcon size={22} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="Disconnect"
                onClick={() => disconnect()}
              >
                <LogoutIcon size={22} />
              </HeaderGlobalAction>
            </>
          );
        case AuthState.PREPARE_MESSAGE:
          return (
            <>
              <HeaderGlobalAction aria-label="Preparing Message">
                <WaitIcon size={22} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="Disconnect"
                onClick={() => disconnect()}
              >
                <LogoutIcon size={22} />
              </HeaderGlobalAction>
            </>
          );
        case AuthState.MESSAGE_READY:
          return (
            <>
              <HeaderGlobalAction aria-label="Waiting for Signature">
                <WaitIcon size={22} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="Disconnect"
                onClick={() => disconnect()}
              >
                <LogoutIcon size={22} />
              </HeaderGlobalAction>
            </>
          );
        case AuthState.SIGNING_IN:
          return (
            <>
              <HeaderGlobalAction
                aria-label="Cancel Authentication"
                onClick={() => disconnect()}
              >
                <ResetAltIcon size={22} />
              </HeaderGlobalAction>
            </>
          );
        case AuthState.SIGNED_IN:
          return <></>;
      }
  }
}

const mapStateToProps = (state: any) => ({
  walletState: state.auth.walletState,
  authState: state.auth.authState,
  preparedMessage: state.auth.preparedMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  connectWallet: (chain: any) => dispatch(connectWallet({ chain })),
  signIn: (signature: any, client: any) => dispatch(signIn(signature, client)),
  disconnectWallet: () => dispatch(disconnectWallet()),
  signOut: () => dispatch(signOut()),
  prepareSignIn: (address: any, chainId: any, client: any) =>
    dispatch(prepareSignIn({ address, chainId, client })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletConnect);
