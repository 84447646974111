import {
  createSlice,
  createAsyncThunk,
  createListenerMiddleware,
} from "@reduxjs/toolkit";

export interface NotificationSlice {
  open: boolean;
}

// Initial state
const initialState: NotificationSlice = {
  open: false,
};

export const openNotificationPanel = createAsyncThunk(
  "notification/openPanel",
  async (arg, thunkAPI) => {
    // dispatch global closePanels
    thunkAPI.dispatch({ type: "global/closePanels" });
    // open the notification panel
    thunkAPI.dispatch(notificationSlice.actions.openPanel());
  }
);

export const closeNotificationPanel = createAsyncThunk(
  "notification/closePanel",
  async (arg, thunkAPI) => {
    // close the notification panel
    thunkAPI.dispatch(notificationSlice.actions.closePanel());
  }
);

export const toggleNotificationPanel = createAsyncThunk(
  "notification/togglePanel",
  async (arg, thunkAPI) => {
    // dispatch global closePanels only if the notification panel is
    // closed
    if (!thunkAPI.getState().notification.open) {
      thunkAPI.dispatch({ type: "global/closePanels" });
    }

    // toggle the notification panel
    thunkAPI.dispatch(notificationSlice.actions.togglePanel());
  }
);

// Create Listener Middleware
export const notificationListenerMiddleware = createListenerMiddleware();

notificationListenerMiddleware.startListening({
  type: "global/closePanels",
  effect: async (action, listenerApi) => {
    // close the notification panel
    listenerApi.dispatch(notificationSlice.actions.closePanel());
  },
});

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    openPanel: (state) => {
      state.open = true;
    },
    closePanel: (state) => {
      state.open = false;
    },
    togglePanel: (state) => {
      state.open = !state.open;
    },
  },
  extraReducers: (builder) => {},
});
