import {
  //@ts-ignore
  HeaderGlobalAction,
  //@ts-ignore
  HeaderPanel,
  //@ts-ignore
  Switcher,
  //@ts-ignore
  SwitcherItem,
  //@ts-ignore
  SwitcherDivider,
} from "@carbon/react";
//@ts-ignore
import { BrightnessContrast as LightIcon } from "@carbon/icons-react";
import { themeSlice } from "./Theme.slice";
import { connect } from "react-redux";

const ThemeSwitcher = ({ toggleTheme }: any) => {
  return (
    <HeaderGlobalAction aria-label="Switch Theme" onClick={toggleTheme}>
      <LightIcon size={22} />
    </HeaderGlobalAction>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  toggleTheme: () => dispatch(themeSlice.actions.toggleTheme()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitcher);
