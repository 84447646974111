import React from "react";
//@ts-ignore
import { loadingBar } from "./LoadingBar.module.scss";
//@ts-ignore
import { InlineLoading } from "@carbon/react";

export default function Loading() {
  return (
    <div className={loadingBar}>
      <InlineLoading status="active" iconDescription="Loading" description="" />
    </div>
  );
}
