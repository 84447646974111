import React from "react";

//import * as Sentry from "@sentry/nextjs";

interface IProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface IState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  public state: IState = {
    error: null,
  };

  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // log the error to the console
    console.log({ error, errorInfo });

    // check if sentry DSN is defined
    //console.log(Sentry.getCurrentHub());

    // log the error to sentry
    //Sentry.captureException(error);
  }

  render() {
    const { fallback } = this.props;
    // Check if the error is thrown
    if (this.state.error) {
      // return fallback UI
      if (fallback) {
        return fallback;
      }

      // return default UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <p>{this.state.error.name}</p>
          <p>{this.state.error.message}</p>
          <p>{this.state.error.stack}</p>
          <p>{this.state.error.cause}</p>
          <button type="button" onClick={() => this.setState({ error: null })}>
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
