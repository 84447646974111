import {
  createAsyncThunk,
  createSlice,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { updateUser as updateUserMutation } from "./graphql/mutations/user";
import { queryUser } from "./graphql/queries/user";
import { signIn, signOut } from "../Wallet/Wallet.slice";
import { FormState } from "../Types";
import { getClient } from "../urql/client";

// User Data
export interface UserData {
  wallet_id: string;
  email: string;
  username: string;
  notificationSettings?: {
    email: boolean;
    newsletter: boolean;
    push: boolean;
  };
}

// Edit Profile Slice
export interface EditProfile {
  modal: boolean;
  form: FormState;
}

// User Slice
export interface UserSlice {
  userData: UserData;
  editProfile: EditProfile;
  userTab: boolean;
}

// Initial State
const initialState: UserSlice = {
  userData: {
    wallet_id: "",
    email: "",
    username: "",
    notificationSettings: {
      email: false,
      newsletter: false,
      push: false,
    },
  },
  editProfile: {
    modal: false,
    form: FormState.Pending,
  },
  userTab: false,
};

// Async Thunks
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, thunkAPI: any) => {
    try {
      const client = getClient();
      const { data } = await client.query(queryUser, {});

      return data.currentUser;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (
    { username, email, notificationSettings }: any,
    { rejectWithValue }
  ) => {
    try {
      const updated_user = { username, email, notificationSettings };
      const client = getClient();

      const data = await client.mutation(updateUserMutation, {
        updated_user: { username, email, notificationSettings },
      });

      if (data.error) {
        console.log("rejected");
        return rejectWithValue(data.error);
      }

      return updated_user;
    } catch (error) {
      console.log(error);
    }
  }
);

export const openUserTab = createAsyncThunk(
  "user/openUserTab",
  async (_, thunkAPI) => {
    // dispatch global closePanels
    thunkAPI.dispatch({ type: "global/closePanels" });
    // open the notification panel
    thunkAPI.dispatch(userSlice.actions.openUserTab());
  }
);

export const closeUserTab = createAsyncThunk(
  "user/closeUserTab",
  async (_, thunkAPI) => {
    // close the notification panel
    thunkAPI.dispatch(userSlice.actions.closeUserTab());
  }
);

export const toggleUserTab = createAsyncThunk(
  "user/toggleUserTab",
  async (_, thunkAPI) => {
    // dispatch global closePanels only if the notification panel is
    // closed
    if (!thunkAPI.getState().user.userTab) {
      thunkAPI.dispatch({ type: "global/closePanels" });
    }

    // toggle the notification panel
    thunkAPI.dispatch(userSlice.actions.toggleUserTab());
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    openEditProfile: (state: UserSlice) => {
      state.editProfile.modal = true;

      return state;
    },
    closeEditProfile: (state: UserSlice) => {
      state.editProfile.modal = false;

      return state;
    },
    setEditProfile: (state: UserSlice, action) => {
      state.editProfile.form = action.payload;

      return state;
    },
    openUserTab: (state: UserSlice) => {
      state.userTab = true;

      return state;
    },
    closeUserTab: (state: UserSlice) => {
      state.userTab = false;

      return state;
    },
    toggleUserTab: (state: UserSlice) => {
      state.userTab = !state.userTab;

      return state;
    },
    clearUserData: (state: UserSlice) => {
      state.userData = {
        wallet_id: "",
        email: "",
        username: "",
        notificationSettings: {
          email: false,
          newsletter: false,
          push: false,
        },
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const {
        walletId,
        email,
        username,
        notificationSettings,
      } = action.payload;

      state.userData.wallet_id = walletId;
      state.userData.email = email;
      state.userData.username = username;
      state.userData.notificationSettings = notificationSettings;

      return state;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const { username, email, notificationSettings }: any = action.payload;

      state.userData.username = username;
      state.userData.email = email;
      state.userData.notificationSettings = notificationSettings;

      return state;
    });
  },
});

// Create Listener Middleware
const listenerMiddleware = createListenerMiddleware();

// Watch for signOut.fulfilled and clear user data
listenerMiddleware.startListening({
  actionCreator: signOut.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.clearUserData());
  },
});

// Watch for signIn.fulfilled and dispatch fetchUser
listenerMiddleware.startListening({
  actionCreator: signIn.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(fetchUser());
  },
});

listenerMiddleware.startListening({
  actionCreator: userSlice.actions.openEditProfile,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(fetchUser());
  },
});

// Watch for fetchUser.fulfilled and dispatch setEditProfile
listenerMiddleware.startListening({
  actionCreator: fetchUser.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Ready));
  },
});

// Watch for fetchUser.pending and dispatch setEditProfile
listenerMiddleware.startListening({
  actionCreator: fetchUser.pending,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Pending));
  },
});

// Watch for fetchUser.rejected and dispatch setEditProfile
listenerMiddleware.startListening({
  actionCreator: fetchUser.rejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Invalid));
  },
});

// Watch for updateUser.pending and dispatch setEditProfile
listenerMiddleware.startListening({
  actionCreator: updateUser.pending,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Submit));
  },
});

// Watch for updateUser.fulfilled and dispatch setEditProfile
listenerMiddleware.startListening({
  actionCreator: updateUser.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Success));
  },
});

// Watch for updateUser.rejected and dispatch setEditProfile
listenerMiddleware.startListening({
  actionCreator: updateUser.rejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Error));
  },
});

// Watch for setEditProfile success and change to Ready
listenerMiddleware.startListening({
  actionCreator: userSlice.actions.setEditProfile,
  effect: async (action, listenerApi) => {
    if (action.payload === FormState.Success) {
      await new Promise((r) => setTimeout(r, 2000));
      listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Ready));
    }
  },
});

// Watch for setEditProfile error and change to Ready after 5 seconds
listenerMiddleware.startListening({
  actionCreator: userSlice.actions.setEditProfile,
  effect: async (action, listenerApi) => {
    if (action.payload === FormState.Error) {
      await new Promise((r) => setTimeout(r, 5000));
      listenerApi.dispatch(userSlice.actions.setEditProfile(FormState.Ready));
    }
  },
});

listenerMiddleware.startListening({
  type: "global/closePanels",
  effect: async (action, listenerApi) => {
    // close the notification panel
    listenerApi.dispatch(userSlice.actions.closeUserTab());
  },
});

export const userListenerMiddleware = listenerMiddleware;
