import {
  Column,
  Grid,
  SwitcherItem as SwitcherItemCarbon,
} from "@carbon/react";
import React from "react";
//@ts-ignore
import { Launch as LaunchIcon } from "@carbon/icons-react";
import Link from "next/link";

// FormatBytes
//
// returns a human readable string for the size of the File
export function FormatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// Accept a date string and return a formatted date string
// example: input: 2024-04-24T16:39:10.722Z
// example: output: Appril 24, 2024
export function FormatDate(date: string) {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

// accept children as an array of two elements
// and a boolean to indicate if the link is outbound
export const MenuItem = ({ children, outbound, href }: any) => {
  const [icon, text] = React.Children.toArray(children);

  if (href === undefined) {
    href = "#";
  }

  return (
    // <Link
    //   href={href}
    //   style={{ all: "unset", cursor: "pointer" }}
    //   target={outbound ? "_blank" : ""}
    //   rel="noopener noreferrer"
    // >
    <Grid fullWidth style={{ paddingLeft: "0", paddingRight: "0" }}>
      <Column sm={1} md={1} lg={2}>
        {icon}
      </Column>
      <Column sm={3} md={6} lg={11}>
        {text}
      </Column>
      {outbound && (
        <Column sm={0} md={2} lg={3}>
          <LaunchIcon size={20} />
        </Column>
      )}
    </Grid>
    //</Link>
  );
};

export const SwitcherItem = ({
  children,
  outbound,
  href,
  onClick,
  ariaLabel,
  ...props
}: any) => {
  const [icon, text] = React.Children.toArray(children);

  let switcher_item = (
    <SwitcherItemCarbon
      target={outbound ? "_blank" : ""}
      rel="noopener noreferrer"
      handleSwitcherItemFocus={() => {}}
      onClick={onClick}
      {...((ariaLabel && { "aria-label": ariaLabel }) || {
        "aria-label": "Menu item",
      })}
      {...props}
    >
      <Grid fullWidth style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Column sm={2} md={2} lg={3}>
          {icon}
        </Column>
        <Column
          sm={2}
          md={5}
          lg={10}
          style={{
            lineHeight: "1.5rem",
          }}
        >
          <span>{text}</span>
        </Column>
        {outbound && (
          <Column sm={0} md={2} lg={3} style={{ paddingTop: "2px" }}>
            <LaunchIcon size={20} />
          </Column>
        )}
      </Grid>
    </SwitcherItemCarbon>
  );

  if (href != undefined) {
    switcher_item = (
      <Link href={href} passHref legacyBehavior>
        {switcher_item}
      </Link>
    );
  }

  return switcher_item;
};
