import { getClient } from "./client";
import { Provider } from "urql";

// create a Provider component that will wrap the entire application
// The wrapper accepts two arguments: endpoint and token

export const UrqlProvider = ({ children }: any) => {
  const client = getClient();

  return <Provider value={client}>{children}</Provider>;
};
