import { connect } from "react-redux";
import NotificationsPanel from "./Panel";
import { HeaderGlobalAction } from "@carbon/react";
import { Notification as NotificationIcon } from "@carbon/icons-react";
import {
  notificationSlice,
  toggleNotificationPanel,
} from "./Notification.slice";
import React from "react";

function NotificationsBar({ panelOpen, togglePanel }: any) {
  let data = [
    {
      description: "LogRhythm is failing to connect, check timeout.",
      id: "e0d9e3be-2e2e-4ac6-a57f-39537ccd5e0a",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-04T16:51:34.721Z"),
      title: "LogRhythm connection failure",
      type: "error",
      unread: true,
    },
    {
      description: "Unable to communicate with LogDNA.",
      id: "b6f4516d-ca34-4f5b-9896-997e7414ff3e",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-04T16:51:04.730Z"),
      title: "LogDNA cannot be reached.",
      type: "error",
      unread: true,
    },
    {
      description: "Email classification was exported successfully.",
      id: "be8abd30-3bf1-4a2e-a062-1e3700b07603",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-04T16:40:34.721Z"),
      title: "System alert",
      type: "warning",
    },
    {
      description: "Successfully connected cartridge",
      id: "439e0833-6c7a-4e9c-afb3-d850a8532534",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-04T14:51:34.721Z"),
      title: "IBM Cloud Pak for Automation Success",
      type: "success",
    },
    {
      description: "App connection succeeded",
      id: "65f91615-39fc-41e4-a3a3-23de7cf7c81f",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-03T16:51:34.721Z"),
      title: "Successfully connected LogDNA",
      type: "success",
    },
    {
      description: "Allocated app memory low",
      id: "ca53137a-bb24-4684-adae-595424446841",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cloud Foundry app memory",
      type: "warning",
    },
    {
      id: "fd874d4d-6822-4a32-b4a3-423940012cd3",
      link: {
        text: "View logs",
        url: "https://www.carbondesignsystem.com",
      },
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Logs are now being monitored",
      type: "informational",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "d6b8133c-a4f7-4ec0-ae5b-ed8463e2be31",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "adefa847-e597-45a5-b02d-3e6d79419f1b",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "078aa3a7-7ebf-45d9-a728-3b62b5d8b9cd",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "e0040e23-0882-48a7-ad08-3955638c8988",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "dccfb5d9-6f12-4705-9832-9ddb1400465e",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "15f1e31d-cec7-44b3-bc21-0cf41e30beb0",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "05021438-ce41-4190-b831-eef7a0545432",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "92e95522-2027-4486-aeab-bd51f2b2f5af",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
    {
      description:
        "Not able to establish connection with provided cluster. Please check your logs and memory allocation to resolve this issue further.",
      id: "cbcbe97a-e5f5-4c03-8d55-b35a7bb0c29e",
      onNotificationClick: function noRefCheck() {},
      timestamp: new Date("2023-08-02T16:51:34.730Z"),
      title: "Cluster unreachable",
      type: "error",
    },
  ];

  data = [];
  return (
    <>
      <HeaderGlobalAction
        aria-label="Notifications"
        onClick={togglePanel}
        isActive={panelOpen}
      >
        <NotificationIcon size={22} />
      </HeaderGlobalAction>
      <NotificationsPanel
        data={data}
        onClickOutside={function noRefCheck() {}}
        onDismissAllNotifications={function noRefCheck() {}}
        onDismissSingleNotification={function noRefCheck() {}}
        onDoNotDisturbChange={function noRefCheck() {}}
        open={panelOpen}
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  panelOpen: state.notification.open,
});

const mapDispatchToProps = (dispatch: any) => ({
  togglePanel: () => dispatch(toggleNotificationPanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsBar);
