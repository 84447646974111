export const queryUser = `
query {
    currentUser {
        walletId,
        username,
        email,
        notificationSettings {
            email,
            newsletter,
            push,
        }
    }
}
`;
