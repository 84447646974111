import {
  createAsyncThunk,
  createSlice,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { generateNonce } from "./graphql/mutations/nonce";
import { SiweMessage } from "siwe";
import { authenticateMessage } from "./graphql/mutations/authenticate";
import { chainWarningSlice } from "../ChainWarning/ChainWarning.slice";
import { getClient } from "../urql/client";

import { signMessage } from "@wagmi/core";

// LoginState
export enum WalletState {
  CONNECTED, // Web3 wallet is connected but the user has not signed the auth message
  DISCONNECTED, // Web3 wallet is disconnected
  ERROR, // Error state
  CHAIN_WARNING, // User is on the wrong chain
}

export enum AuthState {
  SIGNED_IN, // User has signed the auth message
  SIGNED_OUT, // User has not signed the auth message
  PREPARE_MESSAGE, // App is preparing the auth message
  MESSAGE_READY, // App has prepared the auth message
  SIGNING_IN, // User is signing the auth message
}

export interface ConnectorState {
  chainId: number | null;
  address: string | null;
}

// AuthState
export interface AuthSlice {
  connector: ConnectorState;
  walletState: WalletState;
  authState: AuthState;
  authToken: string | null;
  preparedMessage: Object | null;
  modalOpen: boolean;
  onboarded: boolean;
}

// Initial state
const initialState: AuthSlice = {
  connector: {
    chainId: null,
    address: null,
  },
  walletState: WalletState.DISCONNECTED,
  authState: AuthState.SIGNED_OUT,
  authToken: null,
  preparedMessage: null,
  modalOpen: false,
  onboarded: false,
};

export const connectWallet = createAsyncThunk(
  "auth/connectWallet",
  async (_, _thunkAPI: any) => {
    try {
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const disconnectWallet = createAsyncThunk(
  "auth/disconnectWallet",
  async (_, _thunkAPI: any) => {
    try {
      return true;
    } catch (error) {
      throw error;
    }
  }
);

export const onWalletConnect = createAsyncThunk(
  "auth/onWalletConnect",
  async (_, thunkAPI: any) => {
    try {
      const authToken = thunkAPI.getState().auth.authToken;

      if (authToken) {
        console.log("there is auth token");
      } else {
        // There are no auth token, so we need to prepare the message
        // and ask the user to sign it
        //thunkAPI.dispatch(authSlice.actions.openModal());

        return true;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const setChain = createAsyncThunk(
  "auth/setChain",
  async ({ chainId }: any, thunkAPI: any) => {
    try {
      const acceptedChains = [1, 11155111];

      if (acceptedChains.includes(chainId)) {
        return chainId;
      } else {
        // reject the async thunk
        return thunkAPI.rejectWithValue("wrong chain");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const setAddress = createAsyncThunk(
  "auth/setAddress",
  async ({ address }: any, _thunkAPI: any) => {
    try {
      return address;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (_, thunkAPI: any) => {
    try {
      return true;
    } catch (error) {
      throw error;
    }
  }
);

export const prepareSignIn = createAsyncThunk(
  "auth/prepareSignIn",
  async (_, thunkAPI: any) => {
    try {
      const client = getClient();
      const request = await client.mutation(generateNonce, {});

      const address = thunkAPI.getState().auth.connector.address;
      const chainId = thunkAPI.getState().auth.connector.chainId;
      const nonce = request.data.nonce;

      const message = {
        domain: window.location.host,
        address: address,
        statement:
          "nftxledger.com wants you to sign in with your Ethereum account.",
        uri: window.location.origin,
        version: "1",
        chainId: chainId,
        nonce,
        issuedAt: new Date(Date.now()).toISOString(),
      };

      return message;
    } catch (error) {
      throw error;
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async ({ signature }: any, thunkAPI: any) => {
    try {
      const message = thunkAPI.getState().auth.preparedMessage;
      const siweMessage = new SiweMessage(message);
      const client = getClient();

      const request = await client.mutation(authenticateMessage, {
        message: siweMessage,
        signature,
      });

      const token = request.data.authenticate;

      // store token in local storage
      const storage = window.localStorage;
      storage.setItem("auth_token", token.raw);

      return token.raw;
    } catch (error) {
      console.log("error");
      console.dir(error);
      throw error;
    }
  }
);

export const validateSignature = createAsyncThunk(
  "auth/validateSignature",
  async ({ signObj }: any) => {
    try {
      //const nonce = await prepareSignIn();
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// Create Listener Middleware
const authListenerMiddleware = createListenerMiddleware();

// Watch for signIn.fulfilled and dispatch fetchUser
authListenerMiddleware.startListening({
  actionCreator: connectWallet.fulfilled,
  effect: async (action, listenerApi) => {
    // check if user is onboarded
    if (!listenerApi.getState().auth.onboarded) {
      // open Onboarding Modal
      listenerApi.dispatch(authSlice.actions.openModal());
    }

    // check if authToken exists
    //if (listenerApi.getState().auth.authToken) {
    // dispatch signIn
    //listenerApi.dispatch(signIn());
    //}
  },
});

authListenerMiddleware.startListening({
  actionCreator: prepareSignIn.fulfilled,
  effect: async (action, listenerApi) => {
    // retrieve the prepared message
    const preparedMessage = action.payload;

    // create a SiweMessage object
    const message = new SiweMessage(preparedMessage).prepareMessage();

    // signMessage with the prepared message
    const signature = await signMessage({
      message,
    });

    // dispatch signIn
    listenerApi.dispatch(signIn({ signature }));
  },
});

export { authListenerMiddleware };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    openModal: (state) => {
      state.modalOpen = true;
      return state;
    },
    closeModal: (state) => {
      state.modalOpen = false;
      state.onboarded = true;

      return state;
    },
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectWallet.fulfilled, (state) => {
      state.walletState = WalletState.CONNECTED;

      return state;
    });
    builder.addCase(disconnectWallet.fulfilled, (state) => {
      state.walletState = WalletState.DISCONNECTED;
      state.authState = AuthState.SIGNED_OUT;
      state.authToken = null;
      state.onboarded = false;

      const storage = window.localStorage;
      storage.removeItem("auth_token");

      return state;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.authState = AuthState.SIGNED_OUT;

      return state;
    });
    builder.addCase(prepareSignIn.pending, (state) => {
      state.authState = AuthState.PREPARE_MESSAGE;

      return state;
    });
    builder.addCase(prepareSignIn.rejected, (state) => {
      state.authState = AuthState.SIGNED_OUT;

      return state;
    });
    builder.addCase(prepareSignIn.fulfilled, (state, action) => {
      state.authState = AuthState.MESSAGE_READY;
      state.preparedMessage = action.payload;

      return state;
    });
    builder.addCase(signIn.pending, (state) => {
      state.authState = AuthState.SIGNING_IN;

      return state;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.authState = AuthState.SIGNED_OUT;

      return state;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.authState = AuthState.SIGNED_IN;
      state.authToken = action.payload;

      return state;
    });
    builder.addCase(onWalletConnect.fulfilled, (state) => {
      //state.authState = AuthState.SIGNED_OUT;
    });
    builder.addCase(onWalletConnect.rejected, (state) => {});
    builder.addCase(onWalletConnect.pending, (state) => {
      //state.authState = AuthState.SIGNING_IN;
    });
    builder.addCase(setChain.rejected, (state) => {
      state.walletState = WalletState.CHAIN_WARNING;

      return state;
    });
    builder.addCase(setChain.fulfilled, (state, action) => {
      state.connector.chainId = action.payload;

      return state;
    });
    builder.addCase(setAddress.fulfilled, (state, action) => {
      state.connector.address = action.payload;

      return state;
    });
    builder.addCase(setAddress.rejected, (state) => {
      state.walletState = WalletState.ERROR;

      return state;
    });
  },
});

export const selectAuth = (state: any) => state.auth;
